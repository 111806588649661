<script setup lang="ts">
  import CsrfInput from '@/vue/CsrfInput.vue';
  import { computed, ref } from 'vue';
  import FormException, {
    type IFormErrors,
  } from '@/js/exceptions/FormException';

  const props = defineProps<{
    method?: string;
    class?: string;
    action: string;
    site: 'association' | 'maison';
    strings: {
      subscribe: string;
      ctaLabel: string;
      consent: string;
    };
  }>();

  const email = ref<string>('');
  const consent = ref<boolean>(false);

  const isSubscriptionSuccessful = ref(false);
  const errors = ref<FormException | null>(null);
  const hasErrors = computed<boolean>(
    () => Object.keys(errors.value ?? {}).length > 0
  );

  async function onSubmit(event: Event): Promise<void> {
    const formData: FormData = new FormData(event.target as HTMLFormElement);

    try {
      const response: Response = await fetch(props.action, {
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: formData,
      });

      if (!response.ok) {
        const jsonResponse = await response.json();
        throw new FormException(
          `Response status: ${response.status} ${response.statusText}`,
          jsonResponse.errors
        );
      }

      isSubscriptionSuccessful.value = true;
    } catch (error) {
      if (error instanceof FormException) {
        if (error.formErrors) {
          // Only keep the first error for each field
          const formException = error;
          formException.formErrors = Object.entries(error.formErrors).reduce(
            (formErrors, [key, [value]]) => {
              formErrors[key] = value;
              return formErrors;
            },
            {} as IFormErrors
          );
          errors.value = formException;
        }
        return;
      }

      console.error('Error response from server: ', error);
    }
  }

  // TODO: Implement spam counter-measures
</script>

<template>
  <form
    v-if="!isSubscriptionSuccessful"
    :class="props.class"
    action="/subscribe-newsletter-hp"
    method="get"
    @submit.prevent="onSubmit"
  >
    <csrf-input />

    <slot name="error" v-if="hasErrors" />

    <div class="lg:w-3/5 flex flex-col z-[2]">
      <h2
        class="heading-2 text-white mb-9"
        :class="{ 'lgd:uppercase': site === 'association' }"
      >
        Rester informé
        <span class="block lowercase lgd:text-22">
          c’est déjà un moyen d’agir
        </span>
      </h2>

      <div class="sr-only">
        <label aria-hidden="true">
          <input name="contact" autocomplete="off" />
        </label>
      </div>

      <div class="form__container z-[2]">
        <label for="newsletter-form-email" class="block font-bold mb-4 text-18">
          {{ strings.subscribe }}
        </label>
        <div
          class="flex flex-col lg:flex-row items-center lg:px-1.5 lg:bg-white rounded-[35px]"
        >
          <input
            type="email"
            id="newsletter-form-email"
            class="block p-4 lg:px-4 lg:mr-4 w-full rounded-[35px]"
            name="email"
            required
            aria-describedby="email-msg"
            v-model="email"
          />
          <button
            class="button button--tertiary inline-flex items-center cursor-pointer lgd:mt-5 lg:my-1.5 lg:w-1/3"
            type="submit"
            v-text="strings.ctaLabel"
          />
        </div>

        <div
          v-if="errors?.formErrors?.email"
          id="email-error"
          role="alert"
          class="form__message-error"
          v-text="errors.formErrors.email"
        />

        <div class="form__container text-14 text-left italic mt-4">
          <label
            for="newsletter-form-consent"
            class="form__choice !bg-transparent !p-0"
          >
            <input
              type="checkbox"
              id="newsletter-form-consent"
              name="consent"
              required
              value="check-1"
              v-model="consent"
            />
            <div class="form__choice-label">
              <i class="smp-icon-checkbox-uncheck"></i>
              <i class="smp-icon-checkbox"></i>
              {{ strings.consent }}
            </div>
          </label>
        </div>

        <div
          v-if="errors?.formErrors?.consent"
          id="consent-error"
          role="alert"
          class="form__message-error mt-4"
          v-text="errors?.formErrors.consent"
        />

        <div
          v-if="errors?.formErrors?.captcha"
          id="email-error"
          role="alert"
          class="form__message-error"
          v-text="errors?.formErrors.captcha"
        />
      </div>
    </div>

    <slot name="watermark" />
  </form>

  <div v-else>
    <slot name="success" />
  </div>
</template>

<style scoped lang="scss"></style>
